<template>
  <v-main>
    <nav-bar/>
    <template slot:top>
      <v-subheader>
        <h1>Logements supprimé</h1>
      </v-subheader>
      <v-toolbar flat>
        <v-text-field
          class="pr-2 flex-grow-1"
          v-model="query"
          label="Mot clé"
          @input="search"
        />
        <v-select
          v-model="searchField"
          :items="searchFields"
          class="pl-2 flex-grow-0"
          dense
          solo
          @change="search"
        />
      </v-toolbar>
    </template>
    <v-data-table
      :headers="headers"
      :items="items"
      :server-items-length="itemsLength"
      :options.sync="options"
      :must-sort="true"
      :footer-props="{'items-per-page-options':[50]}"
      dense
    >
    <template
      v-slot:item.restoreDeleteProperty="{ item }"
    >
      <v-icon
        @click="restoreDeleteProperty(item.uid)"
      >
        mdi-undo
      </v-icon>
    </template>
    </v-data-table>
  </v-main>
</template>

<script>
  import axios from 'axios'
  import navBar from '../components/navBar.vue'
  export default {
    components: {
      navBar
    },
    data() {
      return {
        headers: [
          { text: 'Id', value: 'id'},
          { text: 'Propriétaire', value: 'owner'},
          { text: 'Adresse', value: 'address'},
          { text: 'Ville', value: 'locality'},
          { text: 'Code Postal', value: 'postalCode'},
          { text: 'Type', value: 'type'},
          { text: 'Loyer', value: 'rent'},
          { text: 'Charges', value: 'charges'},
          { text: 'Pièce(s)', value: 'rooms'},
          { text: 'm²', value: 'surface'},
          { text: 'Statut de l\'annonce', value: 'status'},
          { text: 'Date de création', value: 'creationDate'},
          { text: 'Date de Publication', value: 'publicationDate'},
          { text: 'Réactiver le logement', value: 'restoreDeleteProperty'},
        ],
      searchFields: [
        { text: "Dans toutes les colonnes", value: "id,owner,address,locality,postalCode,type,status,published,creationDate,publicationDate"},
        { text: 'Id', value: 'id'},
        { text: "Propriétaire", value: "owner" },
        { text: "Adresse", value: "address" },
        { text: "Ville", value: "locality" },
        { text: "Code Postal", value: "postalCode" },
        { text: "Type", value: "type" },
        { text: "Statut de l'annonce", value: "status" },
        { text: 'Date de création', value: 'creationDate'},
        { text: 'Date de Publication', value: 'publicationDate'},
        { text: "Publié", value: "published" },
      ],
      searchField: "id,owner,address,locality,postalCode,type,status,published,creationDate,publicationDate",
        items: [],
        itemsLength: 0,
        options: {
          sortBy: ['id'],
          sortDesc: [true]
        },
        query: '',
      }
    },

    watch: {
      options: {
        handler () {
          this.getItems()
        },
        deep: true
      },
    },

    methods: {
      getItems () {
        axios.get(process.env.VUE_APP_APIURL + '/deletedProperties', {
          headers: {
            'Access-Token': localStorage.getItem('token')
          },
          params: {
            offset: (this.options.page - 1) * this.options.itemsPerPage,
            limit: this.options.itemsPerPage,
            sort: this.options.sortBy[0] + ' ' + (this.options.sortDesc[0] ? 'DESC' : 'ASC'),
            searchQuery: ((this.query !== '') ? this.query : undefined),
            searchFields: this.searchField
          }
        })
        .then(res => {
          this.items = res.data.map(item => Object.assign(item,
              { type: `${item.type} (${item.typeLabel})` },
              { creationDate: new Date(item.creationDate).toLocaleString() },
              { publicationDate: new Date(item.publicationDate).toLocaleString() },
          ))
        })
        axios.get(process.env.VUE_APP_APIURL + '/deletedProperties', {
          headers: {
            'Access-Token': localStorage.getItem('token')
          },
          params: {
            count: 1,
            searchQuery: ((this.query !== '') ? this.query : undefined),
            searchFields: this.searchField
          }
        })
        .then(res => {
          this.itemsLength = eval(res.data[0].count)
        })
      },

      search () {
        this.options.page = 1
        this.getItems()
      },
      restoreDeleteProperty(propertyUid){
      axios.post(process.env.VUE_APP_APIURL + `/properties/${propertyUid}/restore`, {},{
        headers :{
          'Access-Token': localStorage.getItem('token')
        },
      }).then(res => {
        if ( res.status == 200 ) {
          document.location.reload();
        }
      })
    },
    },
  }
</script>
